import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PageLayout from "../components/page-layout"
import Message from "../components/message"

import Schedule from "../components/schedule-table"
import Lesson from "../components/lesson-table"
import FeeTable from "../components/fee-table"

import Theme from "../images/takarazuka-class-top.jpg"

export default function Home() {
    let data = useStaticQuery(graphql`
    query allTakarazukaJson {
        allLessonsJson(filter: {for: {eq: "takarazuka"}}) {
            edges {
                node {
                    time
                    name
                    week
                    row
                    color
                    grade
                }
            }
        }
        allFeeJson(filter: {for: {eq: "takarazuka"}}) {
            edges {
                node {
                    title1
                    title2
                    type
                    fee
                    count
                }
            } 
        }
    }
    `);

    return (
        <PageLayout title="宝塚音楽学校受験クラス"
            subTitle={`少人数制で、基礎をしっかり
            バレエとジャズを同時に習得`}
            image={Theme}>

            <Message>
                {`
                宝塚受験を決めたら、どこでレッスンをするかが大切です。大手スクール生に負けない、実力重視の受験対策をしています。
                細やかな技術指導、個別面接指導、精神的なフォローは少人数制だからできることです。
                
                大手スクールに入ったけれど、今まで習っていたバレエの先生と言うことが違う、人数が多すぎて見てもらえない、初めてでよくわからなかったなど、いろいろな理由で移籍をしてくる生徒さんも多いです。
                
                初心者の場合、経験者クラスとは別のクラスで基礎レッスンをすることをお勧めしております。 経験者クラスに参加させて、「とにかく真似してやってみて」というやり方は（ケガ防止の観点から）しておりません。 
                
                バレエ経験者の方は、受験課題演習クラスに参加していただけます。 宝塚音楽学校の入学試験特有のやり方がありますので、そちらを強化していきながら、入学後に繋がるレッスンをしています。
                また、入学後の授業に備えてトウシューズのレッスン（許可制）があります。 
                宝塚受験課題にはモダン（ジャズダンス）も含まれますので、同時に受講していただくようにクラス設定をしております。
                 声楽レッスンをご希望の方には、信頼できる先生をご紹介いたしますので、ご相談ください。`}
            </Message>
            <br />
            <div class="message">
                <a href="/voice/#takarazuka">保護者さまの声はこちら＞＞＞</a>
            </div>

            <h3>宝塚音楽学校 受験クラス スケジュール</h3>

            <Schedule data={data} />

            <Message>{`担当講師は、見学・体験レッスン時にお問い合わせください。`}</Message>

            <Lesson title="受験B">
                {`１３歳(中学１年生)から１８歳(高校３年生)のバレエ未経験者、初心者基礎クラス`}
            </Lesson>
            <Lesson title="受験A">
                {`１３歳(中学１年生)から１８歳(高校３年生)のバレエ経験者（目安として３年以上）`}
            </Lesson>
            <Lesson title="個人レッスン">
                {`１レッスン４５分個別予約制
                --
                ご入会時、バレエ未経験者のためのマンツーマン特別レッスン(体験レッスン時にお問い合わせ下さい)`}
            </Lesson>
            <Lesson title="特別レッスン（申込制）">
                {`
                --
                夏期特別レッスン、冬期特別レッスン、一次試験直前特別レッスン、二次試験対策特別レッスン、新曲視唱特別レッスン、模擬試験(夏期、冬期)`}
            </Lesson>

            <Message>
                {`日曜日の受験基礎・演習クラスは、受験用の黒レオタード（丸首、七分袖）とピンクタイツを着用してください。
                髪型は受験時の髪型にして下さい。`}
            </Message>

            <h3>受験クラス　お月謝・料金表</h3>

            <FeeTable data={data} base = "基本料金（ジャズクラスを含む、月額・税込）" />

            <Message>
                {`お休みした場合、他の日に振替レッスンができます。(レッスン細則をご確認ください。)
                チケット受講もできますので、地方からの受講をご希望の方はお問い合わせ下さい。

                ※維持費は、コロナ等感染症対策衛生費及び冷暖房費として、１ヶ月につき1,100円を頂戴いたします。`}
            </Message>

            <div className="pass-records">
                <h4 className="line">
                    合格実績
                </h4>
                <ul>
                    <li>2021年5月スタジオ開設</li>
                    <li>2022年度110期生2名合格</li>
                    <li>2024年度112期生2名合格</li>
                </ul>
            </div>

            <div className="letter">
                <h4>受験生の皆さんへ</h4>
                <Message>
                    {`
                    あなたが宝塚受験を決めたきっかけは何でしょうか。
                    宝塚が大好きだから、あの華やかなステージに立ちたいから、それは一番大切な気持ちです。
                    その気持ちの意味を掘り下げていくと、あの舞台からのエネルギーが自分を元気にしてくれたり、勇気をもらったり、見えない何かを受け取ったからではないでしょうか。
                    ただ自分が輝いて嬉しいだけではなく、客席で観てくださる方々に元気になってもらえたり、勇気を出してもらえたり、喜んで頂くことができる、そのために劇団生達はどんなに大変なことがあっても、日々のお稽古や準備を頑張っていけるのです。
                    今はまだこの言葉の意味がわからなくても、そういうところを目指す心構えを一緒につくっていきましょう。
                    技術だけではなくて、今後のあなたを支える大きな原動力になる大切な事を、たくさん吸収して欲しいと思います。
                    `}
                </Message>
            </div>

            <div className="letter">
                <h4>バレエ経験のない受験生へ</h4>
                <Message>
                    {`
                    バレエをやっていないと宝塚には入れないからどうしよう、レッスンに追いつけるのだろうかと、不安でいっぱいだと思います。
                    大丈夫です。
                    高校生からバレエを始めた方も、たくさん合格されています。
                    ただし、まず念頭においてほしいのは、宝塚音楽学校は「合格すれば良い」という場所ではないという事です。
                    入学試験は初めの通過点でしかなく、宝塚音楽学校に入学すればすぐに同期生と成績を競うようになり、宝塚歌劇団に入団すれば踊ることは仕事になるのですから、受験演習ばかりで基礎レッスンをしっかり積んでいないと、後で大きな後悔をすることになります。
                    「今からそんな事をしてたら間に合わない」と焦る気持ちは理解できますが、基礎を飛び越えようとせずに覚悟を決めて一歩ずつ進んで行くのが、最短の道です。
                    一緒に頑張っていきましょう。
                    `}
                </Message>
            </div>

            <div className="letter">
                <h4>宝塚受験生の保護者の皆様へ</h4>
                <Message>
                    {`
                    ある日お子さんが宝塚を受験したいと言い出して、未知の世界に戸惑っておられる方も多いと思います。
                    かつての私達もそうでした。
                    私が高校１年生で宝塚を受験したいと言った時、両親にも私にも芸能や宝塚受験に関する知識はほとんど無く何から手を付けたら良いのかわからないという感じでした。
                    当時は今のようなネット社会ではありませんでしたので、知識のありそうな知り合いに電話をし、つてをたどってたどって、やがてあるジャズダンススタジオに行きつきました。
                    私に宝塚への道をつけて下さったのは、そこで出会った先生です。
                    私がして頂いたことを、皆さんにお渡ししていこうと思っております。

                    受験には当然ながら合否があります。
                    狭き門ではありますが、挑戦しなければ絶対に合格はできません。
                    もし不合格だったとしても、本気で挑んだことが必ず大きな財産になると思っています。
                    これ以上できないくらいに努力してみるという経験はなかなかできるものではありません。

                    思春期のお子さんが周りや家族に大きな夢を公言するのは、とても勇気のいることでしょう。
                    まずはそのことに拍手を送ります。
                    本人がやればできると思っている時点で、第一関門突破です。
                    自分の夢を認めてもらえて、応援されたことのある人は、いつか応援をする側の人間に育っていくと信じています。
                    一緒に支えていきましょう。
                    `}
                </Message>
            </div>
        </PageLayout >
    )
}